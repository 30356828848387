<template>
  <div class="trainingHome">
<!--    <h3>框架训练</h3>   白内障，青光眼，角膜病，眼底病，眼外伤，干眼诊疗-->
    <ul class="pdd-2">
      <li @click="$router.push({path: '/training/trainingJudge'})">
        <img src="@/assets/img/conrea/contact_demo.png" alt="">
        <p>训练诊断</p>
      </li>
      <li @click="$router.push({path: '/training/trainingPlan'})">
        <img src="@/assets/img/conrea/contact_inquiry.png" alt="">
        <p>训练方案</p>
      </li>
      <li @click="$router.push({path: '/corneaContact/Iframe?type=9'})">
        <img src="@/assets/img/conrea/contact_check.png" alt="">
        <p>训练记录</p>
      </li>
    </ul>
    <ul class="pd-4">
      <li @click="$router.push({path: '/training/trainingConclusion'})">
        <img src="@/assets/img/conrea/contact_order.png" alt="">
        <p>
          训练结论
        </p>
      </li>
      <li @click="$router.push({path: '/corneaContact/Iframe?type=11'})">
        <img src="@/assets/img/conrea/contact_recheck.png" alt="">
        <p>训练操作指导书</p>
      </li>
      <li style="opacity: 0;">
        <img src="@/assets/img/conrea/contact_protocal.png" alt="">
        <p>在线训练</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "trainingHome",
  data() {
    return {

    }
  },
  created() {
    this.$store.commit('isShow/upBottom', false)
  },
  methods: {
    go() {

    }
  }
}
</script>

<style scoped lang="scss">
  .trainingHome {
    width: 100vw;
    height: 87vh;
    overflow: hidden;
  }
  h3 {padding: .5rem 0;}
  .pdd-2 {padding-top: 15vh}
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      padding: 0 .6rem;
      width: 11em;
      cursor: pointer;
      margin: 0 .6rem;
      img {
        display: block;
        width: 1.8rem;
        margin: 0 auto;
        margin-bottom: .2rem;
        @media (max-width: 1200px) {
          width: 120px;
        }
      }
    }
  }
  .pd-4 {padding-top: 4vh}
</style>
